/*
  * Component: style
  * Author: Kevin F M Santana
  * Date: 01/02/2022 - 11:08
  * Version: 1.0.0A
  * @patch-notes - (Change Log)
  *  - 1.0.0A
  *  () O componente style.js foi criado
  */

import styled from 'styled-components'
import BackgroundGranun from '../../assets/images/back-granun.png'

const GlobalWrapper = styled.div`
  background-color: #1d1f24;
  background-image: url(${BackgroundGranun});
  height: 100%;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 60px;
  box-sizing: border-box;
  transition: all 200ms linear;
  overflow: hidden;
  
  main {
    height: inherit;
    transition: all 200ms linear;
    overflow: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  
  @media(max-width: 500px){
    padding: 5px 20px 72px;
  }
`

export default GlobalWrapper
