/*
  * Component: global
  * Author: Kevin F M Santana
  * Date: 01/02/2022 - 10:36
  * Version: 1.0.0A
  * @patch-notes - (Change Log)
  *  - 1.0.0A
  *  () O componente global.js foi criado
  */

import * as React from "react";
import TopBar from "../../components/Topbar";
import {Helmet} from "react-helmet";
import GlobalWrapper from "./style";
import SocialMedia from "../../components/SocialMedia";
import MagicScroll from "../../components/MagicScroll";
import FavIcon from "../../assets/images/kevin-portfolio.png"
import FavIconIco from "../../assets/images/kevin-portfolio_forico.ico"
import {useEffect, useRef, useState} from "react";
import Prayer from "../../components/Prayer";
import {ToastContainer} from "react-toastify";
import LoadingScreen from "../../components/LoadingScreen";
//Fonts
import ZenKurenaidoEot from "../../assets/fonts/Zen_Kurenaido/ZenKurenaido-Regular.eot"
import ZenKurenaidoTtf from "../../assets/fonts/Zen_Kurenaido/ZenKurenaido-Regular.ttf"
import ZenKurenaidoWoff from "../../assets/fonts/Zen_Kurenaido/ZenKurenaido-Regular.woff"
import ZenKurenaidoWoff2 from "../../assets/fonts/Zen_Kurenaido/ZenKurenaido-Regular.woff2"
import RedCometTeam from "../../components/RedCometTeam";


const GlobalLayout = ({onScrollMain, onScroll, scrollStateValue, pageTitle, children, location}) => {

    const [scrollState, setScrollState] = useState('start')
    const [loading, setLoading] = useState(true)
    const mainRef = useRef(null)
    useEffect(() => {
        if (scrollStateValue)
            setScrollState(scrollStateValue)
    }, [scrollStateValue])

    useEffect(() => {
        //setTimeout(()=> setLoading(false),1000)
        setLoading(false)
    }, [])
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <link rel={"icon"} href={FavIcon}/>
                <link rel="shortcut icon" href={FavIconIco} type="image/x-icon"/>
                <link rel={"preload"} href={ZenKurenaidoEot} as={"font"}
                      type={"font/eot"}/>
                <link rel={"preload"} href={ZenKurenaidoTtf} as={"font"}
                      type={"font/ttf"}/>
                <link rel={"preload"} href={ZenKurenaidoWoff} as={"font"}
                      type={"font/woff"}/>
                <link rel={"preload"} href={ZenKurenaidoWoff2} as={"font"}
                      type={"font/woff2"}/>
                <meta name="keywords" content="portfolio, Portfólio, Portfolio, Portfólio Kevin, Portfólio Santana, Kevin, Kevin Ferreira, Kevin F M Santana, Kevin M Santana, Kevin Marques
                designer, dev, developer, analise de sistemas, TI, Dev Kevin, FullStack, Front-End, back-end, "/>
                <meta name="description"
                      content="Portfólio de Apresentação profissional de Kevin F M Santana, desenvolvedor fullstack e analista de sistemas."/>
            </Helmet>
            {loading ?
                <LoadingScreen/> :
                <GlobalWrapper onScroll={(e) => onScroll && onScroll(e)}>
                    <ToastContainer theme={"dark"}/>
                    <TopBar pathName={location.pathname}/>
                    <SocialMedia/>
                    <Prayer/>
                    <RedCometTeam/>
                    <MagicScroll scrollState={scrollState} topRef={mainRef}/>
                    <main id="main" ref={mainRef} onScroll={(e) => {
                        if (onScrollMain) onScrollMain(e)
                        else {
                            if (e.target.scrollTop > 0 && Math.round(e.target.scrollTop + e.target.offsetHeight) < e.target.scrollHeight) {
                                setScrollState('middle')
                            } else if (Math.round(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight) {
                                setScrollState('end')
                            } else {
                                setScrollState('start')
                            }
                        }
                    }}>
                        {children}
                    </main>
                </GlobalWrapper>
            }
        </>
    )
}

export default GlobalLayout
