import styled from 'styled-components'

const LineStyle = styled.div`
  width: 1px;
  height: 20px;
  background-color: #fff;
  box-shadow: 2px 1px 5px rgba(0,0,0,.08);
  padding: 0;
  transition: all 200ms ease-in-out;
  opacity: ${props => !props.show ? '0' : "1"};
  margin: 20px 0;
`

const MagicScrollContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  color: #fff;
  bottom: 40px;
  right: 60px;
  width: 40px;
  cursor: ${props => props.topRef !== undefined ? 'pointer' : 'initial'};
  
   @media(max-width: 500px) {
    right: 5px;
     bottom: 55px;
  }
`

const ScrollText = styled.span`
  transform: rotate(90deg);
  padding: 5px 0 8px 0;
  margin: 0;
  line-height: 1;
  font-size: 11pt;
   user-select: none;
  font-family: ZenKurenaido-Regular, sans-serif;
  cursor: ${props => props.topRef !== undefined ? 'pointer' : 'initial'};
`
export {LineStyle, ScrollText}
export default MagicScrollContainer
