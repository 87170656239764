import styled from 'styled-components'

const HeaderTopbar = styled.header`
  width: 100%;
  height:auto;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-template-areas: "logo nav";
  box-sizing: border-box;
  margin-bottom: 2px;
  transition: all 300ms ease-in;
  z-index: 1;
`

const Navy = styled.nav`
  grid-area: nav;
  ul {
    box-sizing: border-box;
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
  }
  ul li {
    padding: 0 30px;
    text-transform: uppercase;
    font-size: 12pt;
    white-space: nowrap;
    font-family: ZenKurenaido-Regular,sans-serif;
  }
  ul li a {
    color: white; 
    transition: all 300ms ease-in;
  }
  ul li a:hover {
    color: gold;
    text-shadow: 1px 1px 6px rgba(0,0,0,.22);
    padding-top: 1px;
    transition: all 300ms ease-out ;
  }
  
  ul li.active a {
    transition: all 300ms ease-out ;
    border-bottom: 1px solid #fff;
  }
  
  @media(max-width: 500px) {
    ul {
      padding: 0 0 0 8px;
    }
    ul li {
      padding: 0 4px;
      font-size: 8pt !important;
    }
    
  }
`
export {Navy}
export default HeaderTopbar
