/*
  * Component: Topbar
  * Author: Kevin F M Santana
  * Date: 01/02/2022 - 10:56
  * Version: 1.0.0A
  * @patch-notes - (Change Log)
  *  - 1.0.0A
  *  () O componente Topbar.js foi criado
  */


import Logo from "../Logo";
import * as React from "react";
import HeaderTopbar, {Navy} from "./style";
import AniLink from "gatsby-plugin-transition-link/AniLink"

const TopBar = ({pathName}) => {
    return (
        <HeaderTopbar>
            <Logo/>
            <Navy>
                <ul>
                    <li className={pathName === "/" || pathName === "" ? "active":""} style={{display: "inherit"}}><AniLink fade to={"/"}>Início</AniLink></li>
                    <li className={pathName === "/about" ? "active":""} style={{display: "inherit"}}><AniLink fade to={"/about"}>Sobre Mim</AniLink></li>
                    <li className={pathName.match(/\/projects\/*/) ? "active":""} style={{display: "inherit"}}><AniLink fade to={"/projects"}>Projetos</AniLink></li>
                    <li className={pathName === "/contact" ? "active":""} style={{display: "inherit"}}><AniLink fade to={"/contact"}>Contato</AniLink></li>
                </ul>
            </Navy>
        </HeaderTopbar>
    )
}

export default TopBar
