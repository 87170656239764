import LoadingContainer, {OverlayLoading} from "./style";
import {LogoFull} from "../Logo/style";
import React from "react";


const LoadingScreen = () => {
    return (<LoadingContainer>
        <OverlayLoading>
            <div className={'logo'}>
                <LogoFull/>
            </div>
            <p>Aguarde. Estou preparando tudo ;)</p>
        </OverlayLoading>

    </LoadingContainer>);
}

export default LoadingScreen
