import SocialMediasContainer from "./style";
import {AiFillInstagram, FaTwitter, AiOutlineGithub, AiFillMail,} from 'react-icons/all'
import {IconContext} from 'react-icons'
import React from "react";

const commonIconStyles = {
    size: '22px',
    color: '#fff'
}

function onM(e) {
    let targetNode = e.target
    targetNode.style.transition = "all 240ms linear";
    targetNode.style.fill = "gold"

}

function onMO(e) {
    let targetNode = e.target
    targetNode.style.fill = "#fff"
}

const SocialMedia = () => {
    return <SocialMediasContainer>
        <IconContext.Provider value={commonIconStyles}>
            <a target={"_blank"} rel={"noreferrer"} href={"https://www.instagram.com/keu_marks/"} onFocus={onM} onBlur={onMO} onMouseOver={onM} onMouseOut={onMO}><AiFillInstagram/><p>Instagram</p></a>
            <a target={"_blank"} rel={"noreferrer"} href={"https://twitter.com/Kel_Marxs"} onFocus={onM} onBlur={onMO} onMouseOver={onM} onMouseOut={onMO}><FaTwitter/><p>Twitter</p></a>
            <a target={"_blank"} rel={"noreferrer"} href={"https://github.com/ZekDtonik"} onFocus={onM} onBlur={onMO} onMouseOver={onM} onMouseOut={onMO}><AiOutlineGithub/><p>GitHub</p></a>
            <a target={"_blank"} rel={"noreferrer"} href={"mailto:kevin@redcomet.com.br"} onFocus={onM} onBlur={onMO} onMouseOver={onM} onMouseOut={onMO}><AiFillMail/><p>Email</p></a>
        </IconContext.Provider>
    </SocialMediasContainer>
}

export default SocialMedia
