import styled from 'styled-components'

const SocialMediasContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: all 200ms linear;
  z-index: 100000;
  a {
    margin: 10px 0;
    transition: all 200ms linear;
    display: flex;
    align-items: center;
  }
  a > svg:hover {
    padding-left: 6px;
    fill: gold
  }
  
  a > svg:hover + p, a:focus > svg + p {
    opacity: 1;
    transition: all 200ms linear;
    visibility: visible;
  }
  
  a > p {
    opacity: 0;
    visibility: hidden;
    transition: all 200ms linear;
    display: inline-block;
    margin: 0;
    padding-left: 5px;
    color: #fff;
    font-family: ZenKurenaido-Regular,sans-serif;
  }
  @media(max-width: 500px) {
    left: 5px;
    bottom: 75px
  }
 
`
export default SocialMediasContainer;
