import MagicScrollContainer, {LineStyle, ScrollText} from "./style";
import React from "react";

const Line = (props) => {
    return <LineStyle {...props}/>
}

const MagicScroll = (props) => {

    return <MagicScrollContainer onClick={() => {
        if (props.topRef)
            props.topRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
    }}>
        <Line show={!(props.scrollState === 'start' || props.scrollState === undefined)}/>
        <ScrollText {...props}>{props.scrollState === "start" || props.scrollState === undefined ? "INÍCIO" : (props.scrollState === 'end' ? "FINAL" : "SCROLL")}</ScrollText>
        <Line show={props.scrollState !== 'end'}/>
    </MagicScrollContainer>
}

export default MagicScroll
