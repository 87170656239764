import PrayerBox from "./style";
import React from "react";

const Prayer = (_) => {
    return (<PrayerBox>
        <p>Elevo os meus olhos para os montes: de onde me virá o socorro? O meu socorro vem do Senhor, que fez o céu e a
            terra.</p>
        <h5>Salmos 121:1-2</h5>
    </PrayerBox>)
}

export default Prayer
