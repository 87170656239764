/*
  * Component: Logo
  * Author: Kevin F M Santana
  * Date: 01/02/2022 - 11:00
  * Version: 1.0.0A
  * @patch-notes - (Change Log)
  *  - 1.0.0A
  *  () O componente Logo.js foi criado
  */

import React from "react";
import LogoElement, {LogoFull} from "./style";
import {Link} from 'gatsby'

const Logo = () => {
    return <LogoElement>
            <Link to={'/'}>
                <LogoFull/>
            </Link>
        </LogoElement>

}
export default Logo
