import styled from 'styled-components'

const PrayerBox = styled.div`
  width: 60vw;
  color:#fff;
  font-size: 10pt;
  height: 30px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  text-align: center;
  font-style: italic;
    transition: all 300ms ease-in;
   & > h5 {
    color: #fff;
    text-align: right;
   }
   
   @media(max-width: 500px){
    width: 86vw;
    bottom: 36px
   }
  @media(max-width: 320px){
    width: 98vw;
    bottom: 38px
  }
`

export default PrayerBox
