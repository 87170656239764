import styled from "styled-components";

const RedCometTeamContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  
  @media(max-width: 500px){
    left: 5px;
    bottom: 5px;
    right: initial;
    svg {
      width: 72px;
    }
  }
  @media(max-width: 320px){
   
  }
`

export default RedCometTeamContainer
