import styled from 'styled-components'

import BackgroundGranun from '../../assets/images/back-granun.png'

const LoadingContainer = styled.div`

  position:absolute;
  z-index: 999999;  
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  top: 0;
  left: 0;
`

const OverlayLoading = styled.div`
  display: flex;
  background-color: #1d1f24;
  background-image: url(${BackgroundGranun});
  background-repeat: repeat;
  width: inherit;
  height: inherit;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  & > p {
    text-align: center;
    font-family: sans-serif;
    color: #fff;
    margin-top: 10px;
  }
  
  & > .logo {
    width: 15vw;
  }
  & .blinkCarret {
    animation-name: blink;
    animation-duration: 650ms;
    animation-iteration-count: infinite;
  }
`
export {OverlayLoading}
export default LoadingContainer
