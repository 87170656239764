import styled from 'styled-components'
import LogoImg from "../../assets/svg/kevin-portfolio-full.svg";

const LogoElement = styled.div`
  grid-area: logo;
  width: 120px;
  height:auto;
  fill: white;
  transition: all 300ms ease-in;
  position: relative;
  & a {
     width: inherit;
    height: inherit;
  }
  
  @media(max-width: 550px) {
    width: 70px;
  }
`
const LogoFull = styled(LogoImg)`
  fill: white;
  width: inherit;
  height: inherit;
  cursor: pointer;
  transition: all 300ms ease-in;
`

export {LogoFull}
export default LogoElement
