import RedCometTeamContainer from "./style";
import RedCometLogo from '../../assets/svg/RedComet_logo_line.svg'
import React from "react";

function RedCometTeam(){
    return (
        <RedCometTeamContainer>
            <a href={'https://redcomet.com.br'} referrerPolicy={"no-referrer"} target={"_blank"}>
                <RedCometLogo/>
            </a>
        </RedCometTeamContainer>
    )
}

export default RedCometTeam
